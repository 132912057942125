<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/digitalService' }"
          >扩容提质服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>代收/代付</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <!-- <p>
          <span>代收业务：代收业务是指银行或支付机构代为收取商户或企业的收款。在代收业务中，客户将自己的收款账户信息告知银行或支付机构，由其代为收取相应的款项，并在收到款项后通知客户。</span>
          <span>代付业务：代付业务是指银行或支付机构代为向商户或企业的供应商、员工等付款。在代付业务中，客户将自己的付款账户信息告知银行或支付机构，由其代为完成相应的付款操作。</span>
          <span>双向代收代付业务：双向代收代付业务是指银行或支付机构同时代为商户或企业完成收款和付款操作。在双向代收代付业务中，客户将自己的收款和付款账户信息均告知银行或支付机构，由其代为完成相应的收付款操作。代收代付业务是银行和支付机构等金融机构提供的一种服务，主要针对商户、企业等客户群体，用于完成与其相关的收款和付款等资金结算活动。</span>
        </p> -->
        <div class="one_title">代收/代付</div>
        <div class="dsf_list">
          <div class="dsf_item">
            <div class="left_box">
              <div class="left_icon">
                <img
                  src="../../assets/img/digitalService/dsf/dsf1.png"
                  alt=""
                />
              </div>
              <div class="left_title">代收业务</div>
            </div>
            <div class="right_content">
              代收业务是指银行或支付机构代为收取商户或企业的收款。在代收业务中，客户将自己的收款账户信息告知银行或支付机构，由其代为收取相应的款项，并在收到款项后通知客户。
            </div>
          </div>
          <div class="dsf_item">
            <div class="left_box">
              <div class="left_icon">
                <img
                  src="../../assets/img/digitalService/dsf/dsf2.png"
                  alt=""
                />
              </div>
              <div class="left_title">代付业务</div>
            </div>
            <div class="right_content">
              代付业务是指银行或支付机构代为向商户或企业的供应商、员工等付款，在代付业务中，客户将自己的付款账户信息告知银行或支付机构。
              由其代为完成相应的付款操作
            </div>
          </div>
          <div class="dsf_item">
            <div class="left_box">
              <div class="left_icon">
                <img
                  src="../../assets/img/digitalService/dsf/dsf2.png"
                  alt=""
                />
              </div>
              <div class="left_title">双向代收代付业务</div>
            </div>
            <div class="right_content">
              双向代收代付业务是指银行或支付机构同时代为商户或企业完成收款和付款操作。在双向代收代付业务中，客户将自己的收款和付款账户信息均告知银行或支付机构，由其代为完成相应的收付款操作。代收代付业务是银行和支付机构等金融机构提供的一种服务，主要针对商户、企业等客户群体，用于完成与其相关的收款和付款等资金结算活动。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn" @click="handleApply">立即体验</div>
    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    // subInfo() {
    //   this.$router.push({
    //     path: "/info",
    //     query: { org: "sz" },
    //   });
    // },
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "10";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            //   this.dialogSuccess = true;
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 100%;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 40px 0;
  text-align: justify;
}

.box p:first-child {
  padding-top: 0;
}

.box p span {
  display: block;
  margin: 10px 0;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.dsf_list {
  width: 100%;
  margin-top: 60px;
}

.dsf_item {
  display: flex;
  align-items: center;
  width: 950px;
  background: #f5f5f5;
  border-radius: 8px;
  border-left: 5px solid #055afe;
  padding: 16px 0;
  margin: 0 auto;
  margin-bottom: 20px;
}

.left_box {
  width: 110px;
  padding: 18px;
  border-right: 1px solid rgba(49, 59, 118, 0.2);
}

.left_icon {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.left_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.left_title {
  font-size: 16px;
  color: #000000;
  margin-top: 10px;
  font-weight: bold;
}

.right_content {
  flex: 1;
  padding: 20px;
  font-size: 14px;
  color: #000000;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
